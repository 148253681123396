import { ProductAttributesType, ProductCategoryAttributesType } from "@utils/Product/interfaces";
import qs from "qs";
import { LocaleCode } from "@utils/Country/countryEnums";

export interface FilterInterface {
  [name: string]:
    | {
        [name: string]:
          | {
              [name: string]: string | boolean | number;
            }
          | string
          | boolean
          | number;
      }
    | string
    | boolean
    | number;
}

export interface QueryOptions {
  priceLevel?: number | number[];
  locale: LocaleCode;
  filters?: FilterInterface;
  populate?: unknown;
  fields?: ProductAttributesType[];
}

export interface QueryCategoryOptions {
  locale: LocaleCode;
  filters?: FilterInterface;
  populate?: unknown;
  fields?: ProductCategoryAttributesType[];
}

export const createProductQuery = ({ priceLevel, locale, populate, filters, fields }: QueryOptions): string => {
  const defaultFields: ProductAttributesType[] = ["productNumber", "productName", "productNameShort"];

  return qs.stringify(
    {
      priceLevel,
      filters: filters,
      sort: ["productNameShort:asc"],
      populate: populate,
      locale,
      pagination: {
        pageSize: 200
      },
      fields: fields ? [...defaultFields, ...fields] : defaultFields,
      publicationState: "live"
    },
    {
      encodeValuesOnly: true
    }
  );
};

export const createCategoryQuery = ({ locale, populate, filters, fields }: QueryCategoryOptions): string => {
  const defaultFields: ProductCategoryAttributesType[] = ["name", "slug", "link"];

  return qs.stringify(
    {
      filters: filters,
      sort: ["order:asc"],
      populate: populate,
      locale,
      pagination: {
        pageSize: 200
      },
      fields: fields ? [...defaultFields, ...fields] : defaultFields,
      publicationState: "live"
    },
    {
      encodeValuesOnly: true
    }
  );
};
